import React, { Component } from "react";
import PropTypes from "prop-types";
import { history as historyPropTypes } from "history-prop-types";
import Draggable from "react-draggable";
import gifshot from "gifshot";
import axios from "axios";
import {
    putImage,
    putVideo,
    getSurvey,
    blobToArrayBufferObject,
    blobToBase64,
    getSetting
} from "../../db";
import CameraPreview from "../CameraPreview";
import { DebugThresholds } from "./DebugThresholds/DebugThresholds";
import { computeChecksumMd5, isBrowserSupported } from "../../assets/utils";
import CameraBtns from "./CameraBtns";
import AppBackground from "./ArtWorks/AppBackground";
import Face from "./ArtWorks/Face";
import Frame from "./ArtWorks/Frame";
import GreenScreenBackground from "./ArtWorks/GreenScreenBackground";
import { SpinnerMsg } from "../SpinnerMsg";
import { Counter } from "./Counter";
import { CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO, CONTENT_TYPE_GIF } from "../../assets/constants";
import "animate.css";
import "./style.css";

const OPTION_SPINNER = true;

export default class CameraActivityView extends Component
{
    timerHandle = null;

    images = [];

    glContext = null;

    resetTimeout = null;

    offScreenCanvases = {};

    CANVAS_NAMES = {
        blurred: "blurred",
        blurredMask: "blurred-mask",
        mask: "mask",
        cameraImage: "cameraImage",
        lowresPartMask: "lowres-part-mask"
    };

    constructor(props)
    {
        super(props);
        this.previewRef = React.createRef();
        this.counterCallback = null;
        this.reinitializeCamera = true;

        this.state = {
            showCountDown: false,
            facingMode: "user",
            facingModeFoundUser: 1,
            facingModeFoundEnv: 1,
            showDebug: false,
            isFacingModeSwapping: false,
            isVideoCaptureStarted: false,
            threshold: 0.85,
            blurArea: 512,
            openChooserFace: false,
            openChooserFrame: false,
            openChooserBackground: false,
            showSpinner: false,
            isCanvasLoaded: false,
            spinnerTimeNotOver: true
        };
    }

    async componentDidMount()
    {
        const { campaign, history } = this.props;
        const { current: previewRef } = this.previewRef;
        const { replace } = history;
        let initializeCamera = null;

        if (previewRef)
        {
            initializeCamera = previewRef.initializeCamera;
        }

        this.reinitializeCamera = false;
        initializeCamera && initializeCamera().then(() =>
        {
            const { mediaStream } = this.previewRef.current;

            if (mediaStream === null)
            {
                console.log("CAMERA NOT INITIALIZED, POPPING BACK");
                replace("/CameraError");
            }
        });

        if (campaign === null)
        {
            replace("/CampaignInitialization");

            return;
        }
        this.images = [];

        await this.checkFacingModes();

        setTimeout(() =>
        {
            this.setState({ spinnerTimeNotOver: false });
        }, 2000);

        this.setContentTypeHandler();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { isCanvasLoaded } = this.state;
        const { current: previewRef } = this.previewRef;
        const { jpgList, isProcessPhotosStarted, isProcessPhotosReady,
            setIsProcessPhotosStarted, testFlag, history
        } = this.props;
        const { replace } = history;
        let initializeCamera = null;

        if (previewRef)
        {
            initializeCamera = previewRef.initializeCamera;
        }

        if (this.reinitializeCamera && initializeCamera)
        {
            initializeCamera().then(() =>
            {
                const { mediaStream } = this.previewRef.current;

                if (mediaStream == null)
                {
                    console.log("CAMERA NOT REINITIALIZED, POPPING BACK");
                    replace("/CameraError");
                }
            });
            this.reinitializeCamera = false;
        }

        const newIsCanvasLoaded = this.previewRef?.current?.canvasRef?.current?.width > 300;

        if (isCanvasLoaded !== newIsCanvasLoaded)
        {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isCanvasLoaded: newIsCanvasLoaded
            });
        }

        if (!testFlag)
        {
            return;
        }

        if (jpgList?.length < 1)
        {
            isProcessPhotosStarted && setIsProcessPhotosStarted(false);

            return;
        }

        if ((isProcessPhotosStarted && isProcessPhotosReady) &&
            (jpgList && jpgList.length > 0))
        {
            this.processListPhotos();
        }
    }

    checkFacingModes = async () =>
    {
        /*
        const constraintsUser = { video: { facingMode: { exact: "user" } } };
        const constraintsEnv = { video: { facingMode: { exact: "environment" } } };

        navigator.mediaDevices.getUserMedia(constraintsUser).then(() => this.setState({ facingModeFoundUser: 1 }))
            .catch((err) => console.log("There is no facingMode \"User\":", err));

        navigator.mediaDevices.getUserMedia(constraintsEnv).then(() => this.setState({ facingModeFoundEnv: 1 }))
            .catch((err) => console.log("There is no facingMode \"Environment\":", err));
         */
    }

    getJpgName = () =>
    {
        const { jpgList, jpgListLength } = this.props;
        let jpgName = null;

        if (jpgList?.length > 0)
        {
            jpgName = jpgList.shift();
            const position = jpgListLength - jpgList.length;
            const percentage = ((position * 100) / jpgListLength).toFixed(0);
            console.log("Processing", jpgName, `[${position}/${jpgListLength}][${percentage}%]`);
        }

        return jpgName;
    }

    onBlobGifPicture = (blobData) =>
    {
        const { campaignID, addImage, testFlag, campaign, campaign: { gif_settings: gifSettings } } = this.props;
        const { images } = this;
        const { canvasRef } = this.previewRef.current;
        const jpgName = this.getJpgName();

        blobToBase64(blobData).then((blobObj) =>
        {
            images.push(blobObj.array);
            if (images.length >= gifSettings.max_gif_frames)
            {
                const newImage = {
                    campaignID,
                    galleryID: campaign.gallery_id,
                    created_at: (new Date()).getTime(),
                    isUploaded: testFlag || 0,
                    deviceID: campaign.device_code
                };
                const _this = this;
                try
                {
                    gifshot.createGIF({
                        // "images": ["http://i.imgur.com/2OO33vX.jpg", "http://i.imgur.com/qOwVaSN.png", "http://i.imgur.com/Vo5mFZJ.gif"]
                        images: images,
                        // The frame duration is in 10 scale.
                        frameDuration: (gifSettings.gif_frame_delay / 100),
                        gifWidth: canvasRef.current.width,
                        gifHeight: canvasRef.current.height
                    }, (obj) =>
                    {
                        if (!obj.error)
                        {
                            fetch(obj.image).then((res) =>
                            {
                                res.arrayBuffer().then((buffer) =>
                                {
                                    newImage.image = {
                                        array: buffer,
                                        mimeType: "image/gif"
                                    };

                                    putImage(newImage).then((photoKey) =>
                                    {
                                        addImage(newImage);
                                        if (_this.props.imageWorker)
                                        {
                                            _this.props.imageWorker.postMessage({
                                                messageType: "upload_images"
                                            });
                                        }
                                        const nextHistory = testFlag ?
                                            `/PhotoShare/image/${photoKey}/${jpgName}` :
                                            `/PhotoShare/image/${photoKey}`;

                                        _this.props.history.replace(nextHistory);
                                    });
                                });
                            });
                        }
                    });
                }
                catch (e)
                {
                    console.log(JSON.stringify(e));
                }
            }
            else
            {
                this.handleGifCapture();
            }
        });
    }

    onBlobPhoto = (blobData) =>
    {
        const { campaignID, surveyID, campaign, addImage, imageWorker, testFlag, history } = this.props;
        const { replace } = history;
        const jpgName = this.getJpgName();

        blobToArrayBufferObject(blobData).then(async (arrayObj) =>
        {
            const newImage = {
                campaignID: campaignID,
                galleryID: campaign.gallery_id,
                created_at: (new Date()).getTime(),
                isUploaded: testFlag || 0,
                deviceID: campaign.device_code,
                image: arrayObj,
                qrcode: null
            };

            if (campaign.is_qrcode_enabled)
            {
                const survey = await getSurvey(surveyID);
                campaign.lead_generation.questions.forEach((question) =>
                {
                    if (question.category === "qrcode")
                    {
                        survey.survey.forEach((answer) =>
                        {
                            if (answer.id === question.id)
                            {
                                newImage.qrcode = answer.answer;
                            }
                        });
                    }
                });
            }

            putImage(newImage).then((photoKey) =>
            {
                addImage(newImage);
                imageWorker && imageWorker.postMessage({ messageType: "upload_images" });

                const nextHistory = testFlag ?
                    `/PhotoShare/image/${photoKey}/${jpgName}` :
                    `/PhotoShare/image/${photoKey}`;

                replace(nextHistory);

                // open result in a new window
                // const win = window.open(`/#/PhotoShare/image/${photoKey}`, "_blank");
                // win.focus();
            });
        });
    }

    onCapture = (canvasRef) =>
    {
        // there should be content Type from store
        // const onBlob = isGifPicture ? this.onBlobGifPicture : this.onBlobPhoto;

        const onBlob = this.onBlobPhoto;
        const blobType = "image/jpeg";
        canvasRef.current.toBlob(onBlob, blobType);
    }

    onCaptureVideo = (file, blobVideo, blobThumbnail) =>
    {
        const { campaign } = this.props;
        const url = "https://api.tagkast.com/v2/galleries/register_video";
        const operatorId = "2083"; // TODO get operator_id

        getSetting("deviceID").then(({ value }) =>
        {
            computeChecksumMd5(file).then((originalChecksum) =>
            {
                const bodyFormData = new FormData();
                bodyFormData.append("gallery_id", campaign.gallery_id);
                bodyFormData.append("is_post_event", "false");
                bodyFormData.append("device_code", value);
                bodyFormData.append("operator_id", operatorId);
                bodyFormData.append("original_checksum", originalChecksum);

                // register video
                axios({
                    method: "post",
                    url: url,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                    .then((response) => this.uploadVideoDirect(response, blobVideo, blobThumbnail))
                    .catch((response) => console.log(response));
            });
        });
    }

    uploadVideoDirect = (response, blobVideo, blobThumbnail) =>
    {
        const { campaignID, campaign, addVideo, videoWorker, history } = this.props;
        const { replace } = history;

        const uploadVideoUrl = response.data.data.output.upload_url;
        const uploadThumbnailUrl = response.data.data.output.upload_thumbnail_url;

        const thumbnailHeaders = new Headers();
        const videoHeaders = new Headers();

        thumbnailHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        videoHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        fetch(uploadThumbnailUrl, {
            method: "PUT",
            body: blobThumbnail,
            headers: thumbnailHeaders
        }).then((resp) => (!resp.ok) && console.log("Error", resp.statusText));
        fetch(uploadVideoUrl, {
            method: "PUT",
            body: blobVideo,
            headers: videoHeaders
        }).then((resp) => (!resp.ok) && console.log("Error", resp.statusText));

        // TODO qrcode

        blobToArrayBufferObject(blobVideo).then(async (arrayObjVideo) =>
        {
            const newVideo = {
                campaignID: campaignID,
                galleryID: campaign.gallery_id,
                created_at: (new Date()).getTime(),
                isUploaded: 0,
                deviceID: campaign.device_code,
                video: arrayObjVideo,
                qrcode: null
            };

            putVideo(newVideo).then(async (videoKey) =>
            {
                addVideo(newVideo);
                videoWorker && videoWorker.postMessage({ messageType: "upload_videos" });

                const nextHistory = `/PhotoShare/video/${videoKey}`;
                replace(nextHistory);
            });
        });
    }

    tick = (type) =>
    {
        // TODO CLEAN that when video part will be ready
        /*
        const { renderToCanvas, restartVideo, startRecording } = this.previewRef.current;

        if (curTime <= 0)
        {
            (type === TICK_VIDEO) ? startRecording() : renderToCanvas();
        }
        else
        {
            restartVideo();
        }
        */
    }

    handleTimerStart = () =>
    {
        const { campaign: { activation_mode: activationMode } } = this.props;

        // TODO add check type

        if (activationMode === "kiosk")
        {
            // do something here with timer starts
        }
    }

    handlePhotoCapture = () =>
    {
        const { renderToCanvas } = this.previewRef.current;

        renderToCanvas();
    }

    handleVideoStart = () =>
    {
        const { current: previewRef } = this.previewRef;

        if (isBrowserSupported() === false)
        {
            return;
        }

        previewRef.startRecording();
    }

    handleVideoStop = () => this.previewRef.current.stopRecording();

    setVideoCaptureStarted = (value) => this.setState({ isVideoCaptureStarted: value });

    handleGifCapture = () =>
    {
        // TODO need to be reworked
        /*
        const {
            state: { countdown },
            props: { campaign: { gif_settings: gifSettings, activation_mode: activationMode } },
            images: { length }
        } = this;

        const countdownNew = gifSettings.shot_duration_in_milliseconds / 1000;
        const countdownTextTempStr = ((gifSettings.max_gif_frames - length) === 1) ? "" : "s left";
        let countdownTextNew = `${gifSettings.max_gif_frames - length} picture${countdownTextTempStr}`;

        if (length === 0 && activationMode === "kiosk" && countdown > 0)
        {
            console.log("Gif capture - Kiosk, 0 images, and have countdown greater than 0");
            countdownTextNew = countdownNew.to_s;
        }
        else
        {
            console.log("Gif capture - Not kiosk or we have images or countdown is 0");
        }

        this.setState({
            isGifPicture: true,
            countdown: countdownNew,
            countdownText: countdownTextNew,
        });

        this.timerHandle = setInterval(this.tick, 1000, TICK_GIF);
         */
    }

    handleDone = () =>
    {
        const { history } = this.props;

        history.push("/KioskStartScreen");
    }

    setContentTypeHandler = () =>
    {
        const { contentType } = this.props;

        switch (contentType)
        {
            case CONTENT_TYPE_STILL:
                this.counterCallback = this.handlePhotoCapture;
                break;

            case CONTENT_TYPE_VIDEO:
                this.counterCallback = this.handleVideoStart;
                break;

            case CONTENT_TYPE_GIF:
                this.counterCallback = this.handleGifCapture;
                break;

            default:
                console.error("Invalid contentType: [", contentType, "]");
                break;
        }
    }

    handleSwap = () =>
    {
        const { facingMode } = this.state;

        const facingModeNew = (facingMode === "user") ? "environment" : "user";

        this.reinitializeCamera = true;
        this.setState({
            facingMode: facingModeNew,
            isFacingModeSwapping: true
        });
    }

    finishFacingModeSwapping = () => this.setState({ isFacingModeSwapping: false });

    startProcessListPhotos = () =>
    {
        // TODO check that api

        const {
            jpgList,
            setIsProcessPhotosStarted,
            setIsProcessPhotosReady,
            isProcessPhotosStarted,
            isProcessPhotosReady
        } = this.props;

        if (!jpgList || jpgList.length === 0)
        {
            console.log("Jpg List is empty. Check jpg-list.json or go to AppLoader.");

            return;
        }

        this.handlePhotoCapture();

        isProcessPhotosReady && setIsProcessPhotosReady(false);
        !isProcessPhotosStarted && setIsProcessPhotosStarted(true);
    }

    processListPhotos = () =>
    {
        // TODO check that api

        const { setIsProcessPhotosReady, isProcessPhotosReady } = this.props;

        isProcessPhotosReady && setIsProcessPhotosReady(false);

        this.handlePhotoCapture();
    }

    processPhotoReady = () =>
    {
        const { setIsProcessPhotosReady, testFlag, isProcessPhotosReady } = this.props;

        testFlag && setTimeout(() =>
        {
            !isProcessPhotosReady && setIsProcessPhotosReady(true);
        }, 2000);
    }

    changeThreshold = ({ target }) => this.setState({ threshold: target.value });

    changeBlur = ({ target }) => this.setState({ blurArea: target.value });

    setChooserModalFaceOpen = () => this.setState({ openChooserFace: true });

    setChooserModalFaceClosed = () =>
    {
        this.reinitializeCamera = true;
        this.setState({ openChooserFace: false });
    }

    setChooserModalFrameOpen = () => this.setState({ openChooserFrame: true });

    setChooserModalFrameClosed = () =>
    {
        this.reinitializeCamera = true;
        this.setState({ openChooserFrame: false });
    }

    setChooserModalBackgroundOpen = () => this.setState({ openChooserBackground: true });

    setChooserModalBackgroundClosed = () =>
    {
        this.reinitializeCamera = true;
        this.setState({ openChooserBackground: false });
    }

    setShowCountDownOn = () => this.setState({ showCountDown: true });

    getSpinnerOrButtons = () =>
    {
        const { isVideoCaptureStarted, isCanvasLoaded, facingModeFoundUser, facingModeFoundEnv, facingMode,
            showCountDown, spinnerTimeNotOver } = this.state;
        const facingModeCount = facingModeFoundUser + facingModeFoundEnv;

        if (OPTION_SPINNER && !isCanvasLoaded && spinnerTimeNotOver)
        {
            return (
                <SpinnerMsg
                    showSpinner={!isCanvasLoaded}
                    text="Loading..."
                />
            );
        }

        if (showCountDown && !isVideoCaptureStarted)
        {
            return null;
        }

        return (
            <Draggable bounds="body" handle=".cursor">
                <CameraBtns
                    facingMode={facingMode}
                    startProcessListPhotos={this.startProcessListPhotos}
                    handleSwap={this.handleSwap}
                    setChooserModalBackgroundOpen={this.setChooserModalBackgroundOpen}
                    setChooserModalFrameOpen={this.setChooserModalFrameOpen}
                    setChooserModalFaceOpen={this.setChooserModalFaceOpen}
                    handleDone={this.handleDone}
                    stopRecording={this.handleVideoStop}
                    isVideoCaptureStarted={isVideoCaptureStarted}
                    facingModeCount={facingModeCount}
                    setShowCountDownOn={this.setShowCountDownOn}
                />
            </Draggable>
        );
    };

    render()
    {
        const {
            facingMode, isVideoCaptureStarted, threshold, blurArea, openChooserFace, openChooserFrame,
            openChooserBackground, showDebug, isFacingModeSwapping, showSpinner, showCountDown
        } = this.state;
        const { jpgList, artWorkAppBackgroundUrl, enableFaceMesh, enableGreenScreen, campaign } = this.props;
        const image1st = jpgList.length >= 1 ? jpgList[0] : null;
        const isOpenChooser = openChooserFace || openChooserFrame || openChooserBackground;

        const spinnerOrButtons = this.getSpinnerOrButtons();

        return (
            <div>
                <SpinnerMsg
                    id="spinner"
                    showSpinner={showSpinner}
                    text="Processing..."
                />
                <div className="maxWidthHeight100 widthHeight100 CameraActivity_DivImg">
                    <img
                        className="maxWidthHeight100 widthHeight100 heightFillAvailable CameraActivity_Img"
                        src={artWorkAppBackgroundUrl}
                        alt="App Background"
                    />
                </div>
                <div className="CameraActivity_Row">
                    <AppBackground />
                    {enableFaceMesh && (
                        <Face
                            openChooserFace={openChooserFace && enableFaceMesh}
                            setChooserModalFaceClosed={this.setChooserModalFaceClosed}
                        />
                    )}
                    <Frame
                        openChooserFrame={openChooserFrame}
                        setChooserModalFrameClosed={this.setChooserModalFrameClosed}
                    />
                    {enableGreenScreen && (
                        <GreenScreenBackground
                            openChooserBackground={openChooserBackground}
                            setChooserModalBackgroundClosed={this.setChooserModalBackgroundClosed}
                        />
                    )}
                    {!isOpenChooser && (
                        <CameraPreview
                            className="cameraPreview"
                            facingMode={facingMode}
                            previewRef={this.previewRef}
                            onCapture={this.onCapture}
                            jpgName={image1st}
                            campaign={campaign}
                            processPhotoReady={this.processPhotoReady}
                            isFacingModeSwapping={isFacingModeSwapping}
                            finishFacingModeSwapping={this.finishFacingModeSwapping}
                            setVideoCaptureStarted={this.setVideoCaptureStarted}
                            isVideoCaptureStarted={isVideoCaptureStarted}
                            onCaptureVideo={this.onCaptureVideo}
                            threshold={threshold}
                            blurArea={blurArea}
                        />
                    )}
                    {spinnerOrButtons}
                    {showCountDown && (
                        <Counter
                            handleTimerStart={this.handleTimerStart}
                            callback={this.counterCallback}
                        />
                    )}
                    <DebugThresholds
                        showDebug={showDebug}
                        openChooser={openChooserBackground || openChooserFrame || openChooserFace}
                        threshold={threshold}
                        changeThreshold={this.changeThreshold}
                        blurArea={blurArea}
                        changeBlur={this.changeBlur}
                    />
                </div>
            </div>
        );
    }
}

CameraActivityView.defaultProps = {
    campaign: PropTypes.object.isRequired,
    campaignID: PropTypes.number.isRequired,
    surveyID: PropTypes.number.isRequired,
    jpgList: [],
    jpgListLength: 0,
    contentType: PropTypes.string.isRequired,
    testFlag: false,
    isProcessPhotosStarted: false,
    isProcessPhotosReady: false,
    enableFaceMesh: PropTypes.bool.isRequired,
    enableGreenScreen: PropTypes.bool.isRequired,
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setIsProcessPhotosStarted: PropTypes.func.isRequired,
    setIsProcessPhotosReady: PropTypes.func.isRequired,
    addImage: PropTypes.func.isRequired,
    addVideo: PropTypes.func.isRequired,
    artWorkAppBackgroundUrl: PropTypes.string.isRequired
};

CameraActivityView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string }),
    campaignID: PropTypes.number,
    surveyID: PropTypes.number,
    jpgList: PropTypes.instanceOf(Array),
    jpgListLength: PropTypes.number,
    contentType: PropTypes.string,
    testFlag: PropTypes.bool,
    isProcessPhotosStarted: PropTypes.bool,
    isProcessPhotosReady: PropTypes.bool,
    enableFaceMesh: PropTypes.bool,
    enableGreenScreen: PropTypes.bool,
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    history: PropTypes.shape(historyPropTypes),
    setIsProcessPhotosStarted: PropTypes.func,
    setIsProcessPhotosReady: PropTypes.func,
    addImage: PropTypes.func,
    addVideo: PropTypes.func,
    artWorkAppBackgroundUrl: PropTypes.string
};
