import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { Mesh, MeshStandardMaterial } from "three";
import {
    FaceMesh,
    FACEMESH_TESSELATION,
    FACEMESH_RIGHT_EYE,
    FACEMESH_LEFT_EYE,
    FACEMESH_RIGHT_EYEBROW,
    FACEMESH_LEFT_EYEBROW,
    FACEMESH_FACE_OVAL,
    FACEMESH_LIPS
} from "@mediapipe/face_mesh";
import PropTypes from "prop-types";
import { drawConnectors } from "@mediapipe/drawing_utils";
import { FACEMESH_UV_COORDS, FACEMESHTRIANGULATION } from "../../assets/facemeshtriangulation";
import { CLASS_NONE } from "../../assets/constants";
import { calculateFaceMeshScaleFromElement } from "../CameraPreview/math";
import "./style.css";

const OPTIONS = {
    enableFaceGeometry: false,
    maxNumFaces: 1,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
    refineLandmarks: false,
    selfieMode: true,
    useCpuInference: false
};

const DEBUG = false;

const FaceMeshView = ({
    frontFacingPreviewFlipped, frontFacingCameraFlipped, artWorkFaceUrl,
    drawCanvasRef, facingMode, cameraCanvasScaleX, cameraCanvasScaleY, initFaceInstance, setFaceInstance }) =>
{
    const faceVerts = new Float32Array(FACEMESHTRIANGULATION.length * 3);
    const faceBuffer = new THREE.BufferAttribute(faceVerts, 3);
    const faceGeometry = new THREE.BufferGeometry();
    const faceUVs = new Float32Array(FACEMESH_UV_COORDS.length * 2);
    const uvBuffer = new THREE.BufferAttribute(faceUVs, 2);
    let faceNormalsCalculated = false;
    let faceInstance = null;

    const faceImgRef = useRef(null);
    const faceMeshRef = useRef(null);
    const canvasTestRef = useRef(null);

    const reCalculateFaceVerts = (keypoints) =>
    {
        const canvasElement = document.getElementById("canvasRef");
        const scale = calculateFaceMeshScaleFromElement(canvasElement, drawCanvasRef);

        if (!scale)
        {
            return;
        }

        const portraitScale = [2 * cameraCanvasScaleX, 2 * cameraCanvasScaleY];
        const landscapeScale = [2 * cameraCanvasScaleX, 2 * cameraCanvasScaleY];
        if (scale[0] < 0)
        {
            portraitScale[0] = -portraitScale[0];
            landscapeScale[0] = -landscapeScale[0];
        }

        const { width, height } = canvasElement;

        const scaleFacingMode = (width > height) ? landscapeScale : portraitScale;

        for (let i = 0; i < keypoints.length; i++)
        {
            faceVerts[i * 3] = ((1 - keypoints[i].x) - 0.5) * (scaleFacingMode[0]);
            faceVerts[i * 3 + 1] = ((1 - keypoints[i].y) - 0.5) * (scaleFacingMode[1]);
            faceVerts[i * 3 + 2] = 1.0;
        }
    };

    const reCalculateFaceGeometry = () =>
    {
        if (!faceNormalsCalculated)
        {
            faceGeometry.computeVertexNormals();
            faceNormalsCalculated = true;
        }
    };

    const reCalculateFaceBuffer = () =>
    {
        faceBuffer.needsUpdate = true;
    };

    const reCalculateFaceTexture = () =>
    {
        if (!faceInstance)
        {
            return;
        }

        if (!frontFacingPreviewFlipped)
        {
            faceInstance.scale.x = -1.0;
        }
        faceInstance.visible = true;

        setFaceInstance(faceInstance);
    };

    const drawToTestLayerFaceMesh = (multiFaceLandmarks) =>
    {
        const canvasElement = canvasTestRef.current;
        const canvasCtx = canvasElement.getContext("2d");

        canvasCtx.save();

        if (multiFaceLandmarks)
        {
            /* eslint-disable-next-line */
            for (const landmarks of multiFaceLandmarks)
            {
                drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION, { color: "#C0C0C070", lineWidth: 1 });
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, { color: "#FF3030" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, { color: "#FF3030" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, { color: "#30FF30" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, { color: "#30FF30" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, { color: "#E0E0E0" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, { color: "#E0E0E0" });
            }
        }
        canvasCtx.restore();
    };

    const drawToTestLayerImage = (image) =>
    {
        const { width, height } = drawCanvasRef.current;

        canvasTestRef.current.width = width;
        canvasTestRef.current.height = height;
        const canvasElement = canvasTestRef.current;
        const canvasCtx = canvasElement.getContext("2d");
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(image, 0, 0, canvasElement.width, canvasElement.height);
    };

    const drawToCanvasFaceMesh = (multiFaceLandmarks) =>
    {
        const canvasElement = drawCanvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");

        if (!canvasCtx)
        {
            return;
        }

        canvasCtx.save();

        if (multiFaceLandmarks)
        {
            /* eslint-disable-next-line */
            for (const landmarks of multiFaceLandmarks)
            {
                drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION, { color: "#C0C0C070", lineWidth: 1 });
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, { color: "#FF3030" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, { color: "#FF3030" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, { color: "#30FF30" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, { color: "#30FF30" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, { color: "#E0E0E0" });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, { color: "#E0E0E0" });
            }
        }
        canvasCtx.restore();
    };

    const drawAll = (results) =>
    {
        if (DEBUG)
        {
            drawToTestLayerImage(results.image);
            drawToTestLayerFaceMesh(results.multiFaceLandmarks);
            drawToCanvasFaceMesh(results.multiFaceLandmarks);
        }
    };

    const onResults = (results) => 
    {
        if (results.multiFaceLandmarks.length > 0)
        {
            const keypoints = results.multiFaceLandmarks[0];

            reCalculateFaceVerts(keypoints);
            reCalculateFaceGeometry();
            reCalculateFaceBuffer();
            reCalculateFaceTexture();

            drawAll(results);
        }
    };

    const cleanUpFunc = () => 
    {
        faceMeshRef.current && faceMeshRef.current.close(); 
    };

    const initFaceGeometry = () =>
    {
        faceGeometry.setIndex(FACEMESHTRIANGULATION);
        faceGeometry.setAttribute("position", faceBuffer);
        faceGeometry.setAttribute("uv", uvBuffer);
    };

    const initFaceUVs = () =>
    {
        for (let i = 0; i < FACEMESH_UV_COORDS.length; i++)
        {
            [faceUVs[i * 2]] = FACEMESH_UV_COORDS[i];
            faceUVs[i * 2 + 1] = 1 - FACEMESH_UV_COORDS[i][1];
        }
    };

    const initializeFaceTexture = () =>
    {
        const { current: faceRef } = faceImgRef;

        if (!faceRef)
        {
            console.error("ERROR - faceRef is NULL!");

            return;
        }

        const faceImageTexture = new THREE.CanvasTexture(faceRef);

        if (frontFacingCameraFlipped && facingMode === "user") // Flip face texture to be readable
        {
            for (let i = 0; i < FACEMESH_UV_COORDS.length; i++)
            {
                faceUVs[i * 2] = 1 - faceUVs[i * 2];
            }

            reCalculateFaceBuffer();
        }

        const faceMaterial = new MeshStandardMaterial({
            color: "#ffffff",
            opacity: 0.7,
            map: faceImageTexture,
            wireframe: false,
            side: THREE.FrontSide,
            transparent: true
        });

        faceMaterial.map = faceImageTexture;
        faceMaterial.needsUpdate = true;

        faceInstance = new Mesh(faceGeometry, faceMaterial);

        if (facingMode !== "user" && frontFacingPreviewFlipped)
        {
            faceInstance.scale.x = -1.0;
        }

        initFaceInstance(faceInstance);
    };

    const processFaceMesh = async () =>
    {
        if ((typeof drawCanvasRef.current !== "undefined") && (drawCanvasRef.current !== null))
        {
            await faceMeshRef.current.send({ image: drawCanvasRef.current });
        }

        requestAnimationFrame(processFaceMesh);
    };

    useEffect(() => 
    {
        initFaceGeometry();
        initFaceUVs();
        initializeFaceTexture();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const faceMesh = new FaceMesh({ locateFile: (file) => `/face_mesh/${file}` });

        faceMeshRef.current = faceMesh;

        faceMesh.setOptions(OPTIONS);
        faceMesh.onResults(onResults);

        processFaceMesh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () => 
    {
        cleanUpFunc(); 
    }, []);

    return (
        <div className="FaceMesh">
            <canvas ref={canvasTestRef} id="canvasTestRef" className={CLASS_NONE} />
            <img
                alt="FaceTexture"
                className={CLASS_NONE}
                ref={faceImgRef}
                src={artWorkFaceUrl}
            />
        </div>
    );
};

export { FaceMeshView };

FaceMeshView.defaultProps = {
    frontFacingPreviewFlipped: PropTypes.bool.isRequired,
    frontFacingCameraFlipped: PropTypes.bool.isRequired,
    cameraCanvasScaleX: PropTypes.number.isRequired,
    cameraCanvasScaleY: PropTypes.number.isRequired,
    facingMode: PropTypes.string.isRequired,
    artWorkFaceUrl: PropTypes.string.isRequired
};

FaceMeshView.propTypes = {
    frontFacingPreviewFlipped: PropTypes.bool,
    frontFacingCameraFlipped: PropTypes.bool,
    cameraCanvasScaleX: PropTypes.number,
    cameraCanvasScaleY: PropTypes.number,
    facingMode: PropTypes.string,
    artWorkFaceUrl: PropTypes.string
};
